import React, { useContext } from 'react';
import styled from 'styled-components';
import { Container, Section, media } from 'ui';
import logoRegion from 'images/logo-region.png';
import logoRegionDark from 'images/logo-region-dark.png';
import { WidgetContext } from 'WidgetProvider';

const LogoWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 100%;
  height: auto;

  ${media.desktop`
  max-width: 50vw;
`}
`;
const Logo = styled.img`
  display: block;
  width: 100%;
`;

const HeroWrapper = styled.header`
  text-align: center;
`;
const Baseline = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.backgroundBaseline};
  padding: ${({ theme }) => theme.spacing(0.825)};
  color: #fff;
  font-size: 1.5rem;
  width: 100%;
`;

const Hero = () => {
  const { mode } = useContext(WidgetContext);

  return (
    <Section>
      <Container>
        <HeroWrapper>
          <LogoWrapper>
            <h1 style={{ margin: 0 }}>
              <Logo
                src={mode === 'LIGHT' ? logoRegion : logoRegionDark}
                alt="Handisoins"
              />
            </h1>
            <Baseline>
              Accompagner la santé des personnes en situation de handicap
            </Baseline>
          </LogoWrapper>
        </HeroWrapper>
      </Container>
    </Section>
  );
};

export default Hero;
