import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { graphql, Link } from 'gatsby';
import Seo from '../components/SEO';
import UserProfileForm from '../components/UserProfileForm';
import ArticleCard from '../components/ArticleCard';
import { Container, Section, Title, Button, Buttons } from 'ui';
import renseignement from '../images/renseignement.png';
import PoisMap from '../components/PoisMap';
import Hero from 'components/Hero';

const WrapperLink = styled.div`
  display: flex;
  flex-direction: column;
  color: inherit;
  text-decoration: none;

  img {
    max-width: 300px;
  }

  p {
    text-transform: uppercase;
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.5;
  }
`;

const IndexPage = ({ data, location }) => {
  const actualities = data?.allPrismicActuality?.edges ?? [];
  const categories = data?.allPrismicPoiCategory?.edges ?? [];
  const pois = data?.allPrismicPoi?.edges ?? [];

  return (
    <Layout>
      <Seo
        title="Handisoins"
        description="Accompagner la santé des personnes en situation de handicap"
      />
      <Hero />

      <Section background="secondary" paddingSize="none">
        <Container
          paddingSize="none"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <UserProfileForm />
        </Container>
      </Section>

      <Section>
        <Container>
          <Title>En ce moment</Title>
          {actualities.map(({ node }, index) => (
            <ArticleCard actuality={node?.data} key={node?.id} index={index} />
          ))}
          <Buttons>
            <Button app="pro" as={Link} to="/articles">
              Voir tous les articles
            </Button>
          </Buttons>
        </Container>
      </Section>

      <Section id="carte">
        <PoisMap
          categories={categories}
          pois={pois}
          initialCategory={location?.state?.poiCategory}
        />
      </Section>

      <Section>
        <Container>
          <Title style={{ textAlign: 'center' }}>
            Besoin d’un renseignement ?
          </Title>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <WrapperLink as={Link} to="/questions-frequentes">
              <img src={renseignement} alt="" />
              <p>J’ai une question</p>
            </WrapperLink>

            {/* <div style={{ flexDirection: 'column' }}>
                <img src={question} />
                Nous contacter
              </div> */}
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query ActualitiesQuery {
    allPrismicActuality(
      sort: { fields: first_publication_date, order: DESC }
      filter: { data: { target: { eq: "public" } } }
      limit: 5
    ) {
      edges {
        node {
          data {
            target
            content {
              richText
            }
            thumbnail {
              alt
              url
            }
            title {
              richText
            }
          }
          id
        }
      }
    }
    allPrismicPoiCategory(filter: { data: { target: { eq: "public" } } }) {
      edges {
        node {
          data {
            target
            name {
              text
            }
            icon {
              alt
              url
            }
            target
            description {
              richText
            }
          }
          uid
        }
      }
    }
    allPrismicPoi(limit: 1000) {
      edges {
        node {
          uid
          data {
            address {
              text
            }
            coordinates {
              latitude
              longitude
            }
            description {
              richText
            }
            name {
              text
            }
            category {
              document {
                ... on PrismicPoiCategory {
                  id
                  uid
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
