import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { media, Button, ButtonReset, viewportSizes } from '../ui';
import { RadioButton } from '../ui/forms';
import { MdThumbUp } from 'react-icons/md';
import manThreeQuarter from '../images/man-three-quarter.png';
import womanThreeQuarter from '../images/woman-three-quarter.png';
import { motion } from 'framer-motion';
import { useWindowSize } from 'react-use';
import { navigate } from 'gatsby';

const ROUND_SIZE = 520;

const HalfCircle = styled.form`
  padding-top: 64px;
  height: ${ROUND_SIZE}px;
  width: 100%;
  max-width: calc(${ROUND_SIZE}px * 2);
  border-top-left-radius: calc(${ROUND_SIZE}px * 2);
  border-top-right-radius: calc(${ROUND_SIZE}px * 2);
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  ${media.tablet`
    margin-top: 72px;
    background-color: ${({ theme }) => theme.backgroundAlt};
    align-items: center;
  `}
`;

const ImageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 10%;

  ${({ male }) =>
    male &&
    `
      left: 10%;
      right: auto;
    `}

  ${media.tabletMini`
    right: 0;

    ${({ male }) =>
      male &&
      `
        left: 0;
        right: auto;
      `}
  `}

  ${media.desktop`
    right: 100px;

    ${({ male }) =>
      male &&
      `
        left: 100px;
        right: auto;
      `}
  `}
`;

const Image = styled(motion.img)`
  height: 100%;
  max-height: 50vh;

  ${media.tablet`
    max-height: 520px;
  `}
`;

const FormWrapper = styled.div`
  display: flex;
  z-index: 3;
  flex-direction: column;
  align-items: center;
`;

const FormTitle = styled.p`
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 2rem;
  line-height: 37px;
  margin: 0;
`;

const SelectGender = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};

  label + label {
    margin-top: ${({ theme }) => theme.spacing(0.75)};
  }
`;

const AgeInput = styled.input`
  border: 0;
  padding: 0;
  font-size: 2rem;
  font-weight: 900;
  color: ${({ theme }) => theme.primary};
  text-align: center;
  margin-left: ${({ theme }) => theme.spacing()};
  margin-right: ${({ theme }) => theme.spacing()};
  width: 82px;
  outline: none;
  background: transparent;
  border-bottom: 2px solid ${({ theme }) => theme.text};
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
`;

const getImageExpandedDimensions = viewportWidth => {
  if (viewportWidth < viewportSizes.tabletMini) {
    return { expandedHeight: 200, foldedHeight: 150 };
  }

  if (viewportWidth < viewportSizes.tablet) {
    return { expandedHeight: 300, foldedHeight: 240 };
  }

  return { expandedHeight: 520, foldedHeight: 420 };
};

const UserProfileForm = () => {
  const [selectedGender, setSelectedGender] = useState('homme');
  const [selectedAge, setSelectedAge] = useState('');
  const { width } = useWindowSize();
  const { expandedHeight, foldedHeight } = getImageExpandedDimensions(width);

  const inputRef = useRef();

  const handleSubmitForm = e => {
    e.preventDefault();

    navigate(
      `/prevention-et-soins/${selectedGender}-de-${selectedAge}-an${
        selectedAge > 1 ? 's' : ''
      }`,
    );
  };

  return (
    <HalfCircle onSubmit={handleSubmitForm}>
      <ImageWrapper
        male
        as={ButtonReset}
        type="button"
        onClick={() => setSelectedGender('homme')}
      >
        <Image
          src={manThreeQuarter}
          animate={{
            maxHeight:
              selectedGender === 'homme' ? expandedHeight : foldedHeight,
            opacity: selectedGender === 'homme' ? 1 : 0.5,
          }}
        />
      </ImageWrapper>

      <ImageWrapper
        as={ButtonReset}
        type="button"
        onClick={() => setSelectedGender('femme')}
      >
        <Image
          src={womanThreeQuarter}
          animate={{
            maxHeight:
              selectedGender === 'femme' ? expandedHeight : foldedHeight,
            opacity: selectedGender === 'femme' ? 1 : 0.5,
          }}
        />
      </ImageWrapper>

      <FormWrapper>
        <FormTitle>Je suis…</FormTitle>
        <SelectGender>
          <RadioButton
            label="UN HOMME"
            name="man"
            value={selectedGender === 'homme' ? 0 : 1}
            id={0}
            onChange={() => setSelectedGender('homme')}
          />
          <RadioButton
            label="UNE FEMME"
            name="woman"
            value={selectedGender === 'homme' ? 0 : 1}
            id={1}
            onChange={() => setSelectedGender('femme')}
          />
        </SelectGender>
        <div style={{ display: 'flex', marginTop: 60 }}>
          <FormTitle>J’ai</FormTitle>
          <AgeInput
            ref={inputRef}
            name="age"
            value={selectedAge}
            onChange={e => setSelectedAge(e.target.value)}
            placeholder="--"
          />
          <FormTitle>ans</FormTitle>
        </div>
      </FormWrapper>
      <ButtonWrapper>
        <Button variant="tertiary" type="submit">
          <span>Valider</span>
          <MdThumbUp />
        </Button>
      </ButtonWrapper>
    </HalfCircle>
  );
};

export default UserProfileForm;
